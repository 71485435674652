<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="showpaye"
      max-width="1000px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title>
          <span>
            {{ item.id == -1 ? "Ajouter Paiement" : "Modifier Paiement" }}
          </span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-data-table
            v-model="paye_select"
            :headers="payeheaders"
            :items="paye_list"
            item-key="id"
            class="elevation-1"
            hide-default-footer
            no-data-text="Aucun paiement!"
            single-select
          >
            <template v-slot:item="{ item, isSelected, select, index }">
              <tr
                :class="isSelected ? 'green' : ''"
                @click="toggle(isSelected, select, $event)"
              >
                <td>
                  <v-layout>
                    {{ index + 1 }}
                  </v-layout>
                </td>
                <td class="d-flex align-center">
                  {{ item.nopaye }}
                  <v-icon class="px-1" color="black" v-if="isSelected"
                    >mdi-check</v-icon
                  >
                </td>
                <td>
                  {{ item.paye_ref }}
                </td>
                <td>
                  {{ item.paye_date }}
                </td>
                <td align="right">
                  {{ numberWithSpace(item ? item.montant.toFixed(2) : "0.00") }}
                </td>
                <td align="right">
                  {{ numberWithSpace(item ? item.ttc_det.toFixed(2) : "0.00") }}
                </td>
                <td align="right">
                  {{ numberWithSpace(item ? item.reste.toFixed(2) : "0.00") }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer> </v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click.stop="save"
            v-if="paye_select.length > 0 && change"
          >
            Enregistrer
          </v-btn>
          <v-btn color="blue darken-1" text @click.stop="closePaye">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>
<script>
import CREATE_PAYE_DETAIL from "../graphql/Paiement/CREATE_PAYE_DETAIL.gql";
import UPDATE_PAYE_DETAIL from "../graphql/Paiement/UPDATE_PAYE_DETAIL.gql";

export default {
  name: "factpayeform",
  components: {},
  props: {
    item: Object,
    fact: Object,
    items: Array,
    showpaye: Boolean,
    payes_list: Array,
    mvm: Boolean,
  },
  data() {
    return {
      paye_select: [],
      change: false,
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      old_montant: 0,
      payeheaders: [
        {
          text: "No",
          value: "no",
          selected: true,
        },
        {
          text: "Code",
          value: "nopaye",
          selected: true,
        },
        {
          text: "Ref.",
          value: "paye_ref",
          selected: true,
        },
        {
          text: "Date",
          value: "paye_date",
          selected: true,
        },

        {
          text: "Paiement",
          value: "montant",
          selected: true,
          slot: "cur",
          align: "end",
        },
        {
          text: "Lettré",
          value: "ttc_det",
          selected: true,
          slot: "cur",
          align: "end",
        },
        {
          text: "Reste",
          value: "reste",
          selected: true,
          slot: "cur",
          align: "end",
        },
      ],
      editedItem: {},
    };
  },
  mounted() {
    if (this.item) {
      this.editedItem = Object.assign({}, this.item);
      let i = this.paye_list.findIndex((e) => e.id == this.item.paye_id);
      if (i >= 0) this.paye_select.push(this.paye_list[i]);
      this.old_montant = this.editedItem.montant ? this.editedItem.montant : 0;
    }
  },
  computed: {
    paye_list() {
      return this.payes_list
        ? this.payes_list.filter(
            (elm) =>
              elm.tier_id == this.fact.tier_id &&
              elm.taux_avance == 0 &&
              (elm.reste > 0 || elm.id == this.item.paye_id)
          )
        : [];
    },
  },
  methods: {
    toggle(isSelected, select) {
      this.change = true;
      select(!isSelected);
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    sumItems(key) {
      // sum data in give key (property)
      return this.items.reduce((a, b) => a + (b[key] || 0), 0);
    },
    closePaye() {
      this.$emit("close");
    },

    save() {
      this.editedItem.paye_id = this.paye_select[0].id;
      let i = this.paye_list.findIndex(
        (elm) => elm.id == this.editedItem.paye_id
      );
      if (i >= 0) {
        this.editedItem.nopaye = this.paye_list[i].nopaye;
        this.editedItem.paye_date = this.paye_list[i].paye_date;
        this.editedItem.montant_paye = this.paye_list[i].montant;
      }

      this.paye_select[0].reste <=
      this.fact.mont_ttc - this.sumItems("montant") + this.old_montant
        ? (this.editedItem.montant = this.paye_select[0].reste)
        : (this.editedItem.montant =
            this.fact.mont_ttc - this.sumItems("montant") + this.old_montant);

      i = this.items.findIndex((elm) => elm.paye_id == this.editedItem.paye_id);
      if (i >= 0) {
        this.editedItem.id = this.items[i].id;
        this.editedItem.montant =
          this.editedItem.montant + this.items[i].montant;
      }

      this.progress = true;
      if (this.editedItem.id > 0) {
        this.$apollo
          .mutate({
            mutation: UPDATE_PAYE_DETAIL,
            variables: {
              payedetail: {
                id: this.item.id,
                paye_id: this.editedItem.paye_id,
                montant: this.editedItem.montant,
                write_uid: this.$store.state.me.id,
              },
            },
          })
          .then(() => {
            this.$emit("change", this.editedItem);
            this.progress = false;
            this.items.splice(this.item.index, 1, this.editedItem);
            this.closePaye();
          })
          .catch((error) => {
            this.loading = false;
            this.snackbar_text = error.message;
            this.snackbar_color = "error";
            this.snackbar = true;
          });
      } else {
        this.$apollo
          .mutate({
            mutation: CREATE_PAYE_DETAIL,
            variables: {
              payedetail: {
                paye_id: this.editedItem.paye_id,
                fact_id: this.mvm ? null : this.fact.id,
                mvm_id: this.mvm ? this.fact.id : null,
                montant: this.editedItem.montant,

                create_uid: parseInt(this.$store.state.me.id),
                write_uid: parseInt(this.$store.state.me.id),
              },
            },
          })
          .then((data) => {
            this.editedItem.id = data.data.createPayeDetail.id;
            this.$store.dispatch("Changed", true);
            this.$emit("change", this.editedItem);
            this.$emit("add", this.editedItem);
            this.items.push(this.editedItem);
            this.progress = false;
            this.closePaye();
          })
          .catch((error) => {
            this.loading = false;
            this.snackbar_text = error.message;
            this.snackbar_color = "error";
            this.snackbar = true;
          });
      }
    },
  },
};
</script>
